import { ABOUT_US_URL, COMMON_EMAIL, NEWS_CENTER, TELE_NUMBER } from '@/constants/global'
import { ProductCatalogData } from '@/models/products/product-catalog'
import Image from '@/ui/widgets/image'
import { message } from 'antd'
import cls from 'classnames'
import Link from 'next/link'
import CopyIcon from 'public/copy.svg'
import Mail from 'public/mail.svg'
import IconMinus from 'public/minus.svg'
import Phone from 'public/phone.svg'
import IconPlus from 'public/plus.svg'
import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'

import styles from './index.module.scss'

interface IMenus {
  label: string
  submenus: {
    label: string
    href?: string
    icon?: ReactNode
    target?: string
    copy?: boolean
  }[]
}

const Submenu: FC<{ children: ReactNode; text: string }> = ({ children, text }) => {
  const [fold, setFold] = useState(true)

  const onClickFoldBtn = useCallback(() => {
    setFold(!fold)
  }, [fold])

  return (
    <div className="flex flex-col relative lg:mt-0 ">
      <h1 className={cls('text-sm text-gray-600 font-bold lg:text-base', styles.menuType)}>{text}</h1>

      {fold ? (
        <IconPlus className="absolute w-3 h-3 right-0 lg:hidden cursor-pointer fill-gray-500" onClick={onClickFoldBtn} />
      ) : (
        <IconMinus className="absolute w-3 h-3 right-0 lg:hidden cursor-pointer fill-gray-500" onClick={onClickFoldBtn} />
      )}
      <div
        className={cls(
          {
            hidden: fold,
          },
          'ml-3 lg:ml-0 lg:block',
          styles.menuItem
        )}
      >
        {children}
      </div>
    </div>
  )
}

const MenuItem: FC<{ children: ReactNode }> = ({ children }) => <div className="text-sm text-gray-600 font-normal flex items-center">{children}</div>

interface SiteFooterProp {
  catalogData: ProductCatalogData[]
}
const SiteFooter = ({ catalogData }: SiteFooterProp) => {
  const submenusForProductList = useMemo(() => {
    return catalogData
      ? catalogData.map((i) => ({
          label: i.name,
          href: `/categories/${i.id}`,
        }))
      : []
  }, [catalogData])

  const menus: IMenus[] = useMemo(() => {
    return [
      {
        label: '产品目录',
        submenus: submenusForProductList,
      },
      {
        label: '关于我们',
        submenus: [
          {
            label: '关于我们',
            href: ABOUT_US_URL,
            target: '_blank',
          },
          {
            label: '新闻中心',
            href: NEWS_CENTER,
            target: '_blank',
          },
        ],
      },
      {
        label: '联系销售',
        submenus: [
          {
            label: COMMON_EMAIL,
            icon: <Mail className="mr-2 stroke-gray-600" />,
            copy: true,
          },
          {
            label: TELE_NUMBER,
            icon: <Phone className="mr-2 w-4 h-4 stroke-gray-600" />,
            copy: true,
          },
        ],
      },
    ]
  }, [catalogData])

  const onCopy = (event: React.MouseEvent, text: string) => {
    event.stopPropagation()
    void navigator.clipboard.writeText(text)
    void message.success('已复制到剪贴板', 3)
  }

  return (
    <div className="flex flex-col bg-white pt-8 lg:pt-16">
      <div className="flex flex-col justify-between items-start lg:flex-row mb-[5.125rem]">
        <div className={cls(styles.logo, 'ml-0 mb-2 lg:ml-3 lg:mb-0')}>
          <Image src="/zeiss-icon.svg" alt="zeiss logo" width="48" height="48" className="w-8 h-8 lg:w-12 lg:h-12" />
        </div>
        <div className={cls('flex flex-col justify-between w-full lg:flex-row lg:w-auto', styles.menus)}>
          {menus.map(({ label, submenus }) => (
            <Submenu text={label} key={`${label}-footer`}>
              {submenus.map((submenu, index) => (
                <MenuItem key={`${submenu.label}-submenus-footer-${index}`}>
                  {submenu.icon}
                  {submenu.href ? (
                    <Link href={submenu.href} target={submenu.target ?? '_self'}>
                      {submenu.label}
                    </Link>
                  ) : (
                    <span>{submenu.label}</span>
                  )}
                  {submenu.copy && (
                    <CopyIcon className="cursor-pointer fill-gray-500 ml-2 w-4 h-4" onClick={(e: React.MouseEvent) => onCopy(e, submenu.label)} />
                  )}
                </MenuItem>
              ))}
            </Submenu>
          ))}
        </div>
        <div className={cls('mt-6 self-center lg:-mt-5 lg:self-start', styles.mediaCodes)}>
          <div className="text-sm text-gray-500 text-center mr-5">
            <Image src="/wechat-mini-code.jpg" alt="" width="100" height="100" />
            <span className="block mt-2">微信小程序</span>
          </div>
          <div className="text-sm text-gray-500 text-center mr-5">
            <Image src="/wechat-official-code.png" alt="" width="100" height="100" />
            <span className="block mt-2">微信公众号</span>
          </div>
          <div className="text-sm text-gray-500 text-center">
            <div className="w-25 h-25 flex items-center justify-center">
              <Image src="/work-qrcode.png" alt="" width="72" height="72" />
            </div>
            <span className="block mt-2">商城客服号</span>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  )
}

export const Copyright: FC<{ isForceWrap?: boolean }> = ({ isForceWrap = false }) => (
  <div
    className={cls('box-border text-2xs text-center pt-5 font-light text-gray-500 ', styles.footer, {
      'lg:flex lg:items-center lg:text-sm lg:pt-0': !isForceWrap,
      [styles.wrapFooter]: isForceWrap,
    })}
  >
    <div className="leading-3 divide-x divide-gray-300 ">
      <span className="px-3">沪ICP备15023068号-1</span>
      <Link className="px-3" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31019002000340" target="_blank">
        沪公网安备 31019002000340号
      </Link>
    </div>
    <div className={cls('ml-auto divide-x divide-gray-300 mt-2 leading-3 ', { 'lg:mt-0': !isForceWrap })}>
      <Link
        className="px-3"
        href="https://www.zeiss.com.cn/gongyeceliang/legal-information/legal-notice.html?vaURL=www.zeiss.com.cn/gongyeceliang/legal-notice"
        target="_blank"
      >
        法律注意事项
      </Link>
      <Link className="px-3" href="/privacy" target="_blank">
        隐私政策
      </Link>
      <Link className="px-3" href="https://www.zeiss.com/compliance/en/home.html?vaURL=www.zeiss.com/compliance" target="_blank">
        合规政策
      </Link>
    </div>
  </div>
)

export default SiteFooter
