import * as React from 'react'
import { message } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { Context, createContext, FC, ReactNode, useContext, useState } from 'react'

interface ContextType {
  layers: number
  toast: MessageInstance
  contextHolder: React.ReactElement | null
}

const defaultContextValue: ContextType = {
  toast: message,
  contextHolder: null,
  layers: 0,
}

const ToastContext: Context<ContextType> = createContext<ContextType>(defaultContextValue)

const useProvideToast: () => ContextType = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const [layers, setLayers] = useState<number>(0)

  return {
    toast: {
      ...messageApi,
      // TODO: other warn/error, etc
      success: (content, duration?, onClose?) => {
        const onClosing = () => {
          setLayers((x) => x - 1)
          onClose?.()
        }
        setLayers((x) => x + 1)
        return messageApi.success(content, duration, onClosing)
      },
    },
    contextHolder,
    layers,
  }
}

export const useToast = () => useContext(ToastContext)

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const toast = useProvideToast()
  return (
    <ToastContext.Provider value={toast}>
      {children}
      {toast.contextHolder}
    </ToastContext.Provider>
  )
}
