'use client'

interface MenuItem {
  clickHandler: VoidFunction
  title: string
}
interface UserMenuProps {
  menus: MenuItem[]
  classname: string
}

const UserMenu = ({ menus, classname }: UserMenuProps) => {
  return (
    <ul className={classname}>
      {menus.map((item) => {
        return (
          <li
            className="hover:bg-gray-100 cursor-pointer flex items-center h-8 lg:h-[38px] py-1.5 px-2 hover:font-medium rounded-sm text-xs lg:text-sm text-gray-600 z-modal"
            onClick={item.clickHandler}
            key={item.title}
          >
            {item.title}
          </li>
        )
      })}
    </ul>
  )
}

export default UserMenu
