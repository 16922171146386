'use client'

import classnames from 'classnames'
import Link from 'next/link'
import React, { FC, HTMLAttributeAnchorTarget, ReactElement } from 'react'

interface ButtonLikeLinkProps {
  href: string
  target?: HTMLAttributeAnchorTarget
  text: string
  className?: string
  textClassName?: string
  iconWidth?: number
  iconHeight?: number
  icon?: ReactElement
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

const predefinedStyle = 'h-8 px-4 border border-gray-700 rounded-sm ml-auto flex items-center text-xs'

// TODO: theme
const ButtonLikeLink: FC<ButtonLikeLinkProps> = ({ href, target, text, onClick, icon, disabled, className, textClassName }) => {
  const inner = (
    <>
      {icon}
      <span className={classnames({ 'ml-2': !!icon }, textClassName)}>{text}</span>
    </>
  )
  return disabled ? (
    <span className={classnames('cursor-not-allowed', predefinedStyle, className)}>{inner}</span>
  ) : (
    <Link href={href} target={target} onClick={onClick} className={classnames(predefinedStyle, className)}>
      {inner}
    </Link>
  )
}

export default ButtonLikeLink
