import { useAuth } from '@/hooks/use-auth'
import axios, { AxiosError, AxiosResponse, CancelToken, RawAxiosRequestConfig } from 'axios'
import { mutate } from 'swr'

interface SWRData<T> {
  arg: T
}

const HTTP_CODE = {
  UNAUTHORIZED: 401,
}

const instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/ecommerce/api`,
})

function useWrapper<T>(
  fetcher: (url: string, config: RawAxiosRequestConfig, data?: SWRData<T>) => Promise<AxiosResponse<T>>
): (url: string, data?: SWRData<T>) => Promise<T> {
  const auth = useAuth()

  return (url: string, data?: SWRData<T> | CancelToken, cancelToken?: CancelToken) => {
    const _data = data instanceof axios.CancelToken ? undefined : data
    const _cancelToken = data instanceof axios.CancelToken ? data : cancelToken

    return fetcher(url, { headers: { Authorization: auth.token?.accessToken, 'X-Refresh-Token': auth.token?.refreshToken }, cancelToken: _cancelToken }, _data)
      .then((resp) => {
        const accessToken = resp.headers.authorization as string
        const refreshToken = resp.headers['x-refresh-token'] as string
        if (refreshToken === 'Expired') {
          auth.signOut()
        } else if (accessToken) {
          auth.signIn(accessToken, refreshToken)
        }
        return resp.data
      })
      .catch((e: AxiosError) => {
        if (e.code === AxiosError.ERR_NETWORK) {
          return Promise.reject({ code: AxiosError.ERR_NETWORK })
        }
        if (e.response?.status === HTTP_CODE.UNAUTHORIZED) {
          auth.signOut()
        }

        return Promise.reject(e.response?.data)
      })
  }
}

function useGet<T>(url: string, params?: object): [string, (url: string) => Promise<T>] {
  const config = {
    url,
    params,
  }

  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig) => instance.get<any, AxiosResponse<T>>(config.url, { ...config, ...extraConfig })),
  ]
}

function usePost<T>(url: string): [string, (url: string, data?: SWRData<T>) => Promise<any>] {
  const config = {
    url,
  }
  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig, data?: SWRData<T>) => instance.post(config.url, data?.arg, { ...config, ...extraConfig })),
  ]
}

function usePut<T>(url: string): [string, (url: string, data?: SWRData<T>) => Promise<any>] {
  const config = {
    url,
  }
  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig, data?: SWRData<T>) => instance.put(config.url, data?.arg, { ...config, ...extraConfig })),
  ]
}

function useDelete<T>(url: string, params?: object): [string, (url: string, data?: SWRData<T>) => Promise<any>] {
  const config = {
    url,
    params,
  }
  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig, data?: SWRData<T>) =>
      instance.delete(config.url, { ...config, data: data?.arg, ...extraConfig })
    ),
  ]
}

function useMutationGet<T>(url: string): [string, (url: string, data?: SWRData<T>) => Promise<any>] {
  const config = {
    url,
  }

  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig, data?: SWRData<T>) =>
      instance.get<any, AxiosResponse<T>>(config.url, {
        ...config,
        params: data?.arg,
        ...extraConfig,
      })
    ),
  ]
}

function useDownload<T>(url: string, params?: object): [string, (url: string) => Promise<T>] {
  const config = {
    url,
    params,
  }

  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig) =>
      instance.get<any, AxiosResponse<T>>(config.url, { ...config, responseType: 'arraybuffer', ...extraConfig })
    ),
  ]
}

function useDownloadPost<T>(url: string): [string, (url: string, data?: SWRData<T>) => Promise<any>] {
  const config = {
    url,
  }
  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig, data?: SWRData<T>) =>
      instance.post(config.url, data?.arg, { ...config, responseType: 'arraybuffer', ...extraConfig })
    ),
  ]
}

function useUpload<T>(url: string): [string, (url: string, data?: SWRData<T>) => Promise<any>] {
  const config = {
    url,
  }
  return [
    instance.getUri(config),
    useWrapper((url: string, extraConfig: RawAxiosRequestConfig, data?: SWRData<T>) =>
      instance.post(config.url, data?.arg, { ...config, ...extraConfig, headers: { ...extraConfig.headers, 'Content-Type': 'multipart/form-data' } })
    ),
  ]
}

const clearCache = () => mutate(() => true, undefined, { revalidate: false })

export { useGet, usePost, useMutationGet, clearCache, usePut, useDelete, useDownload, useUpload, useDownloadPost }

export default instance
