import { useGet } from '@/hooks/use-request'
import { AllSystemDictData } from '@/models/quotations/quotation'
import { Configs } from '@/models/site-configuration/configs'

import { useSWR } from './swr/swr-creator'

export const useConfigs = () => {
  return useSWR(...useGet<Configs>('/configs'), { revalidateIfStale: false })
}

export const useTrainingSites = (type: string) => {
  const url = `/configs/query-all-system-dict`
  return useSWR<AllSystemDictData[]>(...useGet<AllSystemDictData[]>(url, { type }))
}
