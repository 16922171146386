import RefreshIcon from '/public/arrows-rotate.svg'
import CollapseIcon from '/public/collapse.svg'

export default function Custom500() {
  function onRefresh() {
    window.location.reload()
  }

  return (
    <div className="w-full flex flex-col items-center mt-16 mb-30 lg:mt-30 lg:mb-[12.5rem]">
      <CollapseIcon className="w-[26.875rem] lg:w-[35.875rem]" />
      <p className="font-light text-lg text-gray-600">您访问的页面出现了错误，请重新加载</p>
      <button className="bg-transparent flex items-center justify-center mt-4" onClick={onRefresh}>
        <p className="font-bold text-blue-500 text-lg mr-0.5">刷新</p>
        <RefreshIcon className="fill-blue-500" />
      </button>
    </div>
  )
}
