import { Context, createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'

interface ContextType {
  startAnimation: boolean
  titleHeight: string
  titleMarginTop: string
  navHeight: string
  visibleHeight: string
}

const defaultContextValue: ContextType = {
  startAnimation: false,
  titleHeight: '40px',
  titleMarginTop: '0px',
  navHeight: '100px',
  visibleHeight: '140px',
}

const AnimationHeaderContext: Context<ContextType> = createContext<ContextType>(defaultContextValue)

export const useAnimationHeaderState = (): ContextType => {
  const titleHeight = 40
  const navHeight = 100
  const minNavHeight = 72
  const [scrollY, setScrollY] = useState(0)

  const onScroll = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const startAnimation = scrollY >= titleHeight
  const titleMarginTop = startAnimation ? -titleHeight : 0
  const dynamicNavHeight = startAnimation ? Math.max(titleHeight + navHeight - scrollY, minNavHeight) : navHeight
  const visibleHeight = startAnimation ? dynamicNavHeight : titleHeight + dynamicNavHeight

  return {
    startAnimation,
    titleHeight: `${titleHeight}px`,
    titleMarginTop: `${titleMarginTop}px`,
    navHeight: `${dynamicNavHeight}px`,
    visibleHeight: `${visibleHeight}px`,
  }
}

export const useAnimationHeader = () => useContext(AnimationHeaderContext)

export const AnimationHeaderProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dynamicLayoutParams = useAnimationHeaderState()
  return <AnimationHeaderContext.Provider value={dynamicLayoutParams}>{children}</AnimationHeaderContext.Provider>
}
