import { DESKTOP_PRODUCT_CATALOG, TABLET_PRODUCT_CATALOG } from '@/constants/products'
import { useAnimationHeader } from '@/hooks/use-animation-header'
import useBetterMediaQuery from '@/hooks/use-better-media-query'
import { ProductCatalogData } from '@/models/products/product-catalog'
import Image from '@/ui/widgets/image'
import { chunk } from '@/utils/helper'
import cls from 'classnames'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'

import styles from './index.module.scss'
import ArrowRight from '/public/arrow-right.svg'
import CloseIcon from '/public/xmark.svg'

interface ProductCatalogProps {
  setProductVisible: (isVisible: boolean) => void
  visible: boolean
  catalogData: ProductCatalogData[]
}

const ProductCatalog = ({ setProductVisible, visible, catalogData }: ProductCatalogProps) => {
  const catalogRef = useRef<HTMLDivElement>(null)
  const [current, setCurrent] = useState(0)
  const [count, setCount] = useState(DESKTOP_PRODUCT_CATALOG)
  const matches = useBetterMediaQuery('(min-width: 1300px)')
  const router = useRouter()
  const { visibleHeight: top } = useAnimationHeader()

  useEffect(() => {
    setCount(matches ? DESKTOP_PRODUCT_CATALOG : TABLET_PRODUCT_CATALOG)
  }, [matches])

  const categories = useMemo(() => {
    return catalogData.map(({ secondLevelCategories, ...rest }) => {
      return {
        ...rest,
        secondLevelCategories: secondLevelCategories ? chunk(secondLevelCategories, count) : [],
      }
    })
  }, [catalogData, count])

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [visible])

  const onClose = () => {
    setProductVisible(false)
    setCurrent(0)
  }

  const onGotoDetail = async (secondLevelCategoryId: string, thirdLevelCategoryId: string) => {
    await router.push({
      pathname: '/products',
      query: { secondLevelCategoryId, thirdLevelCategoryId: thirdLevelCategoryId },
    })

    onClose()
  }

  const onClickKnowMore = async (id: string) => {
    await router.push(`/categories/${id}`)
    onClose()
  }

  return (
    <div
      className={cls([
        'fixed w-full h-[calc(100vh-6rem)] lg:-mx-[7.5rem] -mx-8 border-gray-300 border-t z-20',
        { hidden: !visible || catalogData.length === 0 },
      ])}
      style={{ top }}
    >
      <div
        className="flex min-h-[30rem]  max-h-full overflow-y-auto pb-[3.625rem] pt-5 xlg:pb-[6.875rem] xlg:pt-6 xlg:px-[7.5rem] px-8 bg-white relative"
        ref={catalogRef}
      >
        <div>
          {categories.map((item, index) => (
            <div key={item.id} className={cls(styles.firstCategoryWrapper, 'xlg:w-[11.25rem] mb-[0.125rem]')}>
              <div
                className={cls(
                  styles.firstCategory,
                  { [styles.active]: current === index },
                  'inline-flex items-center xlg:w-[11.25rem] w-[10.25rem] xlg:h-[3.25rem] h-[3.125rem] xlg:text-sm text-xs cursor-pointer xlg:pl-5 pl-4 hover:font-bold hover:bg-gray-100 font-light'
                )}
                onMouseEnter={() => setCurrent(index)}
              >
                {item.name}
              </div>
            </div>
          ))}
        </div>
        <div className="w-full h-full mt-3">
          <div
            className="flex justify-between gap-6 xlg:gap-8 w-fit mb-6 xlg:mb-8 items-center pl-4 xlg:pl-6"
            onClick={() => void onClickKnowMore(categories[current]?.id)}
          >
            <span className="text-gray-600 text-base xlg:text-lg font-bold">{categories[current]?.name}</span>
            <a className="text-blue-500 text-xs xlg:text-sm flex items-center gap-0.5 xlg:gap-1 cursor-pointer">
              了解更多 <ArrowRight className="w-2 h-2 fill-blue-500 ml-1" />
            </a>
          </div>
          {categories.map((item, index) => (
            <div key={item.id} className={cls('hidden xlg:pl-6 pl-4', styles.secondCategoryWrapper, { [styles.active]: current === index })}>
              {item.secondLevelCategories.map((row, index) => (
                <div key={`secondLevelCategories-${index}`} className={cls('flex xlg:mt-14 mt-5', styles.row, index === 0 && styles.firstRow)}>
                  {row.map((second) => (
                    <div key={second.id} className={cls('flex', styles.secondItem)}>
                      <Image
                        src={second.pictureUrl ?? ''}
                        width={100}
                        height={100}
                        alt="product-category"
                        className="bg-contain xlg:w-[6.25rem] w-[4.625rem] xlg:h-[6.25rem] h-[4.625rem]"
                      />
                      <div className="ml-4 mt-2 break-all flex-1">
                        <div
                          className={cls('xlg:text-base font-bold text-gray-600  border-gray-300 pb-3 hover:cursor-pointer', {
                            'border-b': !!second.thirdLevelCategories,
                          })}
                          onClick={() => void onGotoDetail(second.id, second.thirdLevelCategories ? second.thirdLevelCategories[0].id : '')}
                        >
                          {second.name}
                        </div>
                        {second.thirdLevelCategories && (
                          <>
                            {' '}
                            {second.thirdLevelCategories.map((third) => (
                              <div
                                key={third.id}
                                className="cursor-pointer text-gray-500 font-light xlg:text-sm text-xs hover:underline hover:decoration-solid hover:text-gray-600 hover:decoration-gray-600 xlg:mt-2 mt-3"
                                onClick={() => void onGotoDetail(second.id, third.id)}
                              >
                                {third.name}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
        <CloseIcon
          className="absolute w-4 h-4 right-8 xlg:right-30 fill-gray-700 top-6 cursor-pointer top-4 lg:top-[2.5rem]"
          onClick={onClose}
          data-testid="icon-close"
        />
      </div>
      <div className={cls(styles.overlay)} onClick={onClose} />
    </div>
  )
}

export default ProductCatalog
