import { theme } from '@/../antd.config'
import { NO_CUSTOMER_SERVICE_REQUIRED } from '@/constants/global'
import { CART_NOTIFICATION_KEY } from '@/constants/products'
import { AnimationHeaderProvider } from '@/hooks/use-animation-header'
import { ProvideAuth } from '@/hooks/use-auth'
import useAuthGuard from '@/hooks/use-auth-guard'
import { ToastProvider } from '@/hooks/use-toast'
import { useConfigs } from '@/services/configs'
import { useFeatures } from '@/services/features'
import { useProductCatalog } from '@/services/product'
import canceler from '@/services/swr/canceler'
import errorDispatcher from '@/services/swr/error-dispatcher'
import BackTop from '@/ui/components/shared/back-Top'
import CustomerService from '@/ui/components/shared/customer-service'
import SiteContentWrapper from '@/ui/components/shared/site-content-wrapper'
import SiteFooter from '@/ui/components/shared/site-footer'
import SiteHeader from '@/ui/components/shared/site-header'
import { StyleProvider } from '@ant-design/cssinjs'
import { App, ConfigProvider, message, notification } from 'antd'
import zh_CN from 'antd/locale/zh_CN'
import throttle from 'lodash-es/throttle'
import React, { FC, ReactNode } from 'react'
import { SWRConfig } from 'swr/_internal'

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
message.config({
  duration: 2,
  maxCount: 1,
  getContainer: () => document.getElementById('toast-slot') ?? document.body,
})

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
notification.config({
  maxCount: 1,
})

export const RootLayout: FC<{ children: ReactNode }> = ({ children }) => {
  useAuthGuard()
  useFeatures()
  useConfigs()
  const { data: categoryData = [] } = useProductCatalog()

  if (typeof document !== 'undefined') {
    document.addEventListener(
      'scroll',
      throttle(() => notification.destroy(CART_NOTIFICATION_KEY), 1000)
    )
  }

  return (
    <div className="min-w-[48rem]">
      <div className="flex flex-col lg:min-h-[calc(100vh-30.375rem)]">
        <AnimationHeaderProvider>
          <SiteHeader catalogData={categoryData} />
        </AnimationHeaderProvider>
        <div className="grow mt-[8.75rem]">
          <SiteContentWrapper>{children}</SiteContentWrapper>
        </div>
      </div>
      <div className="px-8 lg:px-30">
        <SiteFooter catalogData={categoryData} />
      </div>
      <CustomerService />
      <BackTop />
    </div>
  )
}

export const PureLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const showCustomerService = !NO_CUSTOMER_SERVICE_REQUIRED.find((item) => location.pathname.includes(item))
  return (
    <>
      {children}
      {showCustomerService && <CustomerService />}
      {showCustomerService && <BackTop />}
    </>
  )
}

export const RootConfig: FC<{ children: ReactNode }> = ({ children }) => (
  <ConfigProvider theme={theme} autoInsertSpaceInButton={false} locale={zh_CN}>
    <StyleProvider>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          shouldRetryOnError: false,
          keepPreviousData: true,
          use: [errorDispatcher, canceler],
        }}
      >
        <ProvideAuth>
          <ToastProvider>
            <App className="h-full">{children}</App>
          </ToastProvider>
        </ProvideAuth>
      </SWRConfig>
    </StyleProvider>
  </ConfigProvider>
)
