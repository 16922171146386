'use client'

import UserMenu from '@/ui/components/shared/site-header/user-menu'
import styles from '@/ui/components/shared/site-header/user-status-bar/index.module.scss'
import UserAvatar from '@/ui/components/shared/site-header/user-status-bar/user-avatar'
import ButtonLikeLink from '@/ui/widgets/link/button-like-link'
import cls from 'classnames'
import { useRouter } from 'next/router'

import UserIcon from '/public/user.svg'

const UnauthorizedUser = () => {
  const menus = [{ clickHandler: () => void router.push('/sign-in'), title: '立即登录' }]
  const router = useRouter()

  return (
    <div className={styles.avatar}>
      <ButtonLikeLink
        href="/sign-in"
        icon={<UserIcon className="fill-gray-600" />}
        iconWidth={12}
        iconHeight={15}
        text="登录"
        className="hidden lg:flex text-gray-600 border-0 h-9 py-2.5 px-3.5 !rounded-[1.25rem] hover:font-medium hover:bg-gray-100 hover:text-gray-600"
        textClassName="text-sm"
      />
      <UserAvatar classname={cls('lg:hidden', styles.icon)} />
      <UserMenu menus={menus} classname={cls(styles.menus, 'lg:!hidden')} />
    </div>
  )
}

export default UnauthorizedUser
