import { UPGRADE_TOKEN_KEY } from '@/constants/global'
import useLocalStorage from '@/hooks/use-local-storage'
import useEffectOnce from '@/hooks/useEffectOnce'
import { useUpgradeInfo } from '@/services/upgrade'
import Loading from '@/ui/widgets/loading'
import getConfig from 'next/config'
import Router, { NextRouter } from 'next/router'
import { NextConfig } from 'next/types'
import { useState } from 'react'

const { publicRuntimeConfig } = getConfig() as NextConfig

const useUpgrade = () => {
  const [pass, setPass] = useState<boolean>(false)
  const { trigger } = useUpgradeInfo()
  const [upgradeToken, setUpgradeToken] = useLocalStorage<string>(UPGRADE_TOKEN_KEY)

  const ZEISS_UPGRADE_URL = (publicRuntimeConfig?.ZEISS_UPGRADE_URL || '') as string

  useEffectOnce(() => {
    const url = new URL(location.toString())
    const searchParams = url.searchParams
    const w = searchParams.get('w')
    if (w) {
      setUpgradeToken(w)
    }
    const handleRouteChange = () => {
      if (pass) return
      void trigger({
        appCode: (publicRuntimeConfig?.APPCODE || '') as string,
        authCode: w ?? upgradeToken,
      })
        .then((res) => {
          if (res?.upgradeStatus === true && location.pathname !== ZEISS_UPGRADE_URL) {
            window.location.href = `${ZEISS_UPGRADE_URL}?startTime=${res.detail?.startTime ?? '-'}&endTime=${res.detail?.endTime ?? '-'}`
          }
        })
        .finally(() => {
          setTimeout(() => {
            setPass(true)
          }, 100)
        })
    }
    // 路由拦截
    handleRouteChange()

    Router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange)
    }
  })

  return {
    LoadingDom: Loading,
    pass,
  }
}

export default useUpgrade
