/* eslint-disable */
import axios from 'axios'
import { Middleware, State } from 'swr'

const canceler: Middleware = (useSWRNext) => {
  return function (key, fetcher, config) {
    function extendedFetcher(...args: any[]) {
      const ct = new axios.CancelToken((canceler) => {
        if (config.cache.get(key)) {
          Object.defineProperty(config.cache.get(key), 'cancel', {
            value: canceler,
            writable: false,
          })
        } else {
          config.cache.set(key, {
            cancel: canceler,
          } as State)
        }
      })

      return fetcher!(...args, ct)
    }

    const next = useSWRNext(key, extendedFetcher, config)

    return Object.assign({}, next, {
      cancel: () => {
        // @ts-ignore
        config.cache.get(key)?.cancel()
      },
    })
  }
}

export default canceler
