import { CheckoutSvcError, ErrorResponse, QuotationSvcError } from '@/models/error/error'
import Custom404 from '@/pages/404'
import Custom500 from '@/pages/500'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'

export const PAGE_BLOCKING_ERROR: Record<string, string[]> = {
  ERROR_404: [
    CheckoutSvcError.QUOTATION_NOT_FOUND,
    CheckoutSvcError.QUOTATION_ACCESS_DENIED,
    CheckoutSvcError.ORDER_NOT_FOUND,
    QuotationSvcError.PROMOTION_UNAVAILABLE,
    QuotationSvcError.PROMOTION_NOT_FOUND,
    QuotationSvcError.PROMOTION_ACCESS_DENIED,
  ],
  ERROR_500: [],
}

const useAppPage = (component: JSX.Element) => {
  const router = useRouter()
  const [errorState, setErrorState] = useState<string | undefined>()

  useEffect(() => {
    const handler = (event: ErrorEvent) => {
      if (event.error) {
        const state = Object.entries(PAGE_BLOCKING_ERROR).find(([_, errors]) => errors.includes((event.error as ErrorResponse).code))?.[0]
        state && setErrorState(state)
      }
    }
    window.addEventListener('error', handler)

    return () => window.removeEventListener('error', handler)
  }, [])

  useEffect(() => {
    const reset = () => setErrorState(undefined)

    router.events.on('routeChangeComplete', reset)
    router.events.on('routeChangeError', reset)
    return () => {
      router.events.off('routeChangeComplete', reset)
      router.events.off('routeChangeError', reset)
    }
  }, [router.events])

  return useMemo(() => {
    switch (errorState) {
      case 'ERROR_404':
        return <Custom404 />
      case 'ERROR_500':
        return <Custom500 />
      default:
        return component
    }
  }, [component, errorState])
}

export default useAppPage
