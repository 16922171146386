export const DESKTOP_RECOMMEND_PRODUCTS = 5
export const TABLET_RECOMMEND_PRODUCTS = 4
export const DESKTOP_PRODUCT_CATALOG = 3
export const TABLET_PRODUCT_CATALOG = 2
export const CART_NOTIFICATION_KEY = 'ADD_TO_CART'

export type ProductAttributes = Record<ProductAttrKey, string>

export enum ProductAttrKey {
  ANGLE1_IN_DEGREE = 'angle1InDegree',
  ANGLE2_IN_DEGREE = 'angle2InDegree',
  LENGTH_IN_MILLIMETRE = 'lengthInMillimetre',
  WIDTH_IN_MILLIMETRE = 'widthInMillimetre',
  WEIGHT_IN_GRAM = 'weightInGram',
  MEASURING_LENGTH_IN_MILLIMETRE = 'measuringLengthInMillimetre',
  MEASURING_LENGTH2_IN_MILLIMETRE = 'measuringLength2InMillimetre',
  MEASURING_LENGTH3_IN_MILLIMETRE = 'measuringLength3InMillimetre',
  SHAFT_IN_MILLIMETRE = 'shaftInMillimetre',
  SHAFT2_IN_MILLIMETRE = 'shaft2InMillimetre',
  SHAFT3_IN_MILLIMETRE = 'shaft3InMillimetre',
  BODY_IN_MILLIMETRE = 'bodyInMillimetre',
  SPHERE_IN_MILLIMETRE = 'sphereInMillimetre',
  MEASURING_VOLUME_X_AXIS_IN_MILLIMETRE = 'measuringVolumeXAxisInMillimetre',
  MEASURING_VOLUME_Y_AXIS_IN_MILLIMETRE = 'measuringVolumeYAxisInMillimetre',
  MEASURING_VOLUME_Z_AXIS_IN_MILLIMETRE = 'measuringVolumeZAxisInMillimetre',
  ACCURACY_LEVEL = 'accuracyLevel',
  APPLICATION = 'application',
  CONNECTION_TYPE = 'connectionType',
  CONNECTION_TYPE_OUT = 'connectionTypeOut',
  GRID = 'grid',
  MATERIAL = 'material',
  PRODUCT_TYPE = 'productType',
  TRAINING_PRODUCT_TYPE = 'trainingProductType',
  SHAFT_MATERIAL = 'shaftMaterial',
  STYLUS_TYPE = 'stylusType',
  STYLUS_TIP = 'stylusTip',
  STYLUS_TIP_MATERIAL = 'stylusTipMaterial',
  MEASURING_SYSTEM_TYPE = 'measuringSystemType',
  LENGTH1_IN_MILLIMETRE = 'length1InMillimetre',
  TRAINING_DURATION = 'trainingDuration',
  TRAINING_LOCATION = 'trainingLocation',
  TRAINING_PRICE_REFERENCE = 'trainingPriceReference',
  TRAINING_TYPE = 'trainingType',
  TRAINING_CONTENT_SHORT = 'trainingContentShort',
}

export const PC67_PRODUCT_ATTR_NAME_MAP: Partial<Record<ProductAttrKey, string>> = {
  [ProductAttrKey.PRODUCT_TYPE]: '课程类型',
}

export const FIELD_CODE_RELATION_MAP: Partial<Record<ProductAttrKey, ProductAttrKey>> = {
  [ProductAttrKey.TRAINING_PRODUCT_TYPE]: ProductAttrKey.PRODUCT_TYPE,
}

export const PRODUCT_ATTR_NAME_MAP: Record<ProductAttrKey, string> = {
  [ProductAttrKey.CONNECTION_TYPE]: '连接方式(内)',
  [ProductAttrKey.CONNECTION_TYPE_OUT]: '连接方式(外)',
  [ProductAttrKey.ACCURACY_LEVEL]: '精度等级',
  [ProductAttrKey.ANGLE1_IN_DEGREE]: '角度1',
  [ProductAttrKey.ANGLE2_IN_DEGREE]: '角度2',
  [ProductAttrKey.APPLICATION]: '应用',
  [ProductAttrKey.BODY_IN_MILLIMETRE]: '直径(DG)',
  [ProductAttrKey.MATERIAL]: '材质',
  [ProductAttrKey.GRID]: '网格',
  [ProductAttrKey.LENGTH_IN_MILLIMETRE]: '长度(L)',
  [ProductAttrKey.WIDTH_IN_MILLIMETRE]: '宽度',
  [ProductAttrKey.MEASURING_LENGTH_IN_MILLIMETRE]: '有效测量长度(ML)',
  [ProductAttrKey.MEASURING_LENGTH2_IN_MILLIMETRE]: '有效测量长度(MLE)',
  [ProductAttrKey.MEASURING_LENGTH3_IN_MILLIMETRE]: '有效测量长度(MLF)',
  [ProductAttrKey.MEASURING_VOLUME_X_AXIS_IN_MILLIMETRE]: 'X方向测量区域',
  [ProductAttrKey.MEASURING_VOLUME_Y_AXIS_IN_MILLIMETRE]: 'Y方向测量区域',
  [ProductAttrKey.MEASURING_VOLUME_Z_AXIS_IN_MILLIMETRE]: 'Z方向测量区域',
  [ProductAttrKey.PRODUCT_TYPE]: '产品类型',
  [ProductAttrKey.SHAFT_MATERIAL]: '测杆材质',
  [ProductAttrKey.SHAFT_IN_MILLIMETRE]: '杆径(DS)',
  [ProductAttrKey.SHAFT2_IN_MILLIMETRE]: '杆径(DSE)',
  [ProductAttrKey.SHAFT3_IN_MILLIMETRE]: '杆径(DSF)',
  [ProductAttrKey.SPHERE_IN_MILLIMETRE]: '球径(DK)',
  [ProductAttrKey.STYLUS_TIP]: '测球类型',
  [ProductAttrKey.STYLUS_TIP_MATERIAL]: '测球材质',
  [ProductAttrKey.STYLUS_TYPE]: '探针类型',
  [ProductAttrKey.WEIGHT_IN_GRAM]: '重量(W)',
  [ProductAttrKey.MEASURING_SYSTEM_TYPE]: '测量系统类型',
  [ProductAttrKey.LENGTH1_IN_MILLIMETRE]: '长度1',
  [ProductAttrKey.TRAINING_DURATION]: '培训时间',
  [ProductAttrKey.TRAINING_LOCATION]: '培训地点',
  [ProductAttrKey.TRAINING_PRICE_REFERENCE]: '培训价格',
  [ProductAttrKey.TRAINING_TYPE]: '训练类型',
  [ProductAttrKey.TRAINING_CONTENT_SHORT]: '课程大纲',
  [ProductAttrKey.TRAINING_PRODUCT_TYPE]: '课程类型',
}

export const PRODUCT_ATTR_UNIT_MAP: Record<ProductAttrKey, string> = {
  [ProductAttrKey.CONNECTION_TYPE]: '',
  [ProductAttrKey.CONNECTION_TYPE_OUT]: '',
  [ProductAttrKey.ACCURACY_LEVEL]: '',
  [ProductAttrKey.ANGLE1_IN_DEGREE]: '°',
  [ProductAttrKey.ANGLE2_IN_DEGREE]: '°',
  [ProductAttrKey.APPLICATION]: '',
  [ProductAttrKey.BODY_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MATERIAL]: '',
  [ProductAttrKey.GRID]: '',
  [ProductAttrKey.LENGTH_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.WIDTH_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MEASURING_LENGTH_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MEASURING_LENGTH2_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MEASURING_LENGTH3_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MEASURING_VOLUME_X_AXIS_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MEASURING_VOLUME_Y_AXIS_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.MEASURING_VOLUME_Z_AXIS_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.PRODUCT_TYPE]: '',
  [ProductAttrKey.SHAFT_MATERIAL]: '',
  [ProductAttrKey.SHAFT_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.SHAFT2_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.SHAFT3_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.SPHERE_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.STYLUS_TIP]: '',
  [ProductAttrKey.STYLUS_TIP_MATERIAL]: '',
  [ProductAttrKey.STYLUS_TYPE]: '',
  [ProductAttrKey.WEIGHT_IN_GRAM]: 'g',
  [ProductAttrKey.MEASURING_SYSTEM_TYPE]: '',
  [ProductAttrKey.LENGTH1_IN_MILLIMETRE]: 'mm',
  [ProductAttrKey.TRAINING_DURATION]: '',
  [ProductAttrKey.TRAINING_LOCATION]: '',
  [ProductAttrKey.TRAINING_PRICE_REFERENCE]: '',
  [ProductAttrKey.TRAINING_TYPE]: '',
  [ProductAttrKey.TRAINING_CONTENT_SHORT]: '',
  [ProductAttrKey.TRAINING_PRODUCT_TYPE]: '',
}
