import { isFunction } from 'lodash'
import { useEffect, useRef } from 'react'

import useLatest from './useLatest'

const useEffectOnce = (fn: () => void) => {
  const once = useRef<boolean>(false)
  if (!isFunction(fn)) {
    console.error(`useEffectOnce expected parameter is a function, got ${typeof fn}`)
  }

  const fnRef = useLatest(fn)

  useEffect(() => {
    if (!once.current) {
      once.current = true
      fnRef.current()
    }
  })
}

export default useEffectOnce
