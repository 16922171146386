import { useAuth } from '@/hooks/use-auth'
import AuthorizedUser from '@/ui/components/shared/site-header/user-status-bar/authorized-user'
import UnauthorizedUser from '@/ui/components/shared/site-header/user-status-bar/unauthorized-user'

const UserStatusBar = ({ onClickMenuItem }: { onClickMenuItem?: () => void }) => {
  const auth = useAuth()
  if (!auth.tokenLoaded) return null

  return auth.authenticated ? <AuthorizedUser onClickMenuItem={onClickMenuItem} /> : <UnauthorizedUser />
}

export default UserStatusBar
