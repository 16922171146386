import { useGet, usePost } from '@/hooks/use-request'
import { ProductFilters } from '@/models/products/filters'
import { RecommendProduct } from '@/models/products/product'
import { ProductConstruction, ProductIdsList, RecommendCategory, RecommendProductResponse } from '@/models/products/product'
import { ProductCatalogData, SecondLevelCategory } from '@/models/products/product-catalog'
import { ProductDetail } from '@/models/products/product-detail'
import { ProductListHeader } from '@/models/products/product-list'

import { useSWR, useSWRMutation } from './swr/swr-creator'

const useProductsCreation = () => {
  const url = 'products'

  return useSWRMutation(...usePost<ProductConstruction>(url))
}

const useRecommendProducts = () => {
  const url = 'products/recommend-products'
  return useSWR(...useGet<RecommendProductResponse>(url))
}

const useProductCatalog = () => {
  const url = 'products/product-catalog'
  return useSWR(...useGet<ProductCatalogData[]>(url))
}

const useProductsCategories = () => {
  const url = 'products/product-category'
  return useSWR(...useGet<RecommendCategory[]>(url))
}

const useProductListHeaders = (categoryId: string) => {
  const url = 'products/product-list-header'
  return useSWR(...useGet<ProductListHeader[]>(url, { secondLevelCategoryId: categoryId }))
}

const useProductList = () => {
  const url = 'products/product-list'
  return useSWRMutation(...usePost(url))
}

const useSecondCatalog = (categoryId: string, config: object) => {
  const url = 'products/product-second-category'
  return useSWR(...useGet<SecondLevelCategory>(url, { id: categoryId }), config)
}

const useProductDetail = (productId: string, authorized: boolean) => {
  const url = 'products/product-detail'

  const [swrUrl, fetcher] = useGet<ProductDetail>(url, { productId })

  return useSWR(authorized ? swrUrl : null, fetcher)
}

const useProductFilters = (categoryId: string) => {
  const url = `products/categories/${categoryId}/filters`

  return useSWR<ProductFilters>(...useGet<ProductFilters>(url), { revalidateOnMount: false })
}

const useProductByIds = () => {
  const url = `products/product-by-ids`

  return useSWRMutation<RecommendProduct[]>(...usePost<ProductIdsList>(url))
}

export {
  useProductsCreation,
  useRecommendProducts,
  useProductCatalog,
  useSecondCatalog,
  useProductsCategories,
  useProductListHeaders,
  useProductList,
  useProductDetail,
  useProductFilters,
  useProductByIds,
}
