import { NextRouter } from 'next/dist/client/router'
import { withRouter } from 'next/router'
import RefreshIcon from 'public/arrows-rotate.svg'
import CollapseIcon from 'public/collapse.svg'
import React, { ErrorInfo, ReactElement } from 'react'

// import * as errorHandler from './error-handler'

interface ErrorBoundaryProps {
  children: ReactElement
  router: NextRouter
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = { hasError: false }
  }

  onRouterChange = () => {
    this.setState({ hasError: false })
  }

  componentDidMount() {
    // errorHandler.register()
    this.props.router.events.on('routeChangeStart', this.onRouterChange)
  }

  componentWillUnmount() {
    // errorHandler.unregister()
    this.props.router.events.off('routeChangeStart', this.onRouterChange)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full flex flex-col items-center mt-16 mb-30 lg:mt-30 lg:mb-[12.5rem]">
          <CollapseIcon className="w-[26.875rem] lg:w-[35.875rem]" />
          <p className="font-light text-lg text-gray-500">您访问的页面出现了错误，请重新加载</p>
          <button className="bg-transparent flex items-center justify-center mt-4" onClick={() => this.setState({ hasError: false })}>
            <p className="font-bold text-blue-500 text-lg mr-0.5">刷新</p>
            <RefreshIcon className="fill-blue-500" />
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
