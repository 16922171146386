import { ABOUT_US_URL } from '@/constants/global'
import { useAnimationHeader } from '@/hooks/use-animation-header'
import { useAuth } from '@/hooks/use-auth'
import useIpadDetect from '@/hooks/use-ipad-detect'
import { ProductCatalogData } from '@/models/products/product-catalog'
import SearchBar from '@/ui/components/shared/search-bar'
import ProductCatalog from '@/ui/components/shared/site-header/product-catalog'
import UserStatusBar from '@/ui/components/shared/site-header/user-status-bar'
import Image from '@/ui/widgets/image'
import ButtonLikeLink from '@/ui/widgets/link/button-like-link'
import cls from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'

import BulkProcurementIcon from '/public/file-check.svg'
import SearchIcon from '/public/search.svg'
import ShoppingCardIcon from '/public/shopping-cart.svg'

interface SiteHeaderProp {
  catalogData: ProductCatalogData[]
}

const SiteHeader = ({ catalogData }: SiteHeaderProp) => {
  const auth = useAuth()
  const [productVisible, setProductVisible] = useState(false)
  const [searchBarVisible, setSearchBarVisible] = useState(false)
  const router = useRouter()
  const { isIpad } = useIpadDetect()

  const isContentManagement = useMemo(() => {
    return router.pathname.includes('content-management') && !productVisible
  }, [router.pathname, productVisible])

  const onOpenProductCatalog = () => {
    if (productVisible) return
    onCloseActive()
    setProductVisible(true)
  }

  const onOpenContent = () => {
    void router.push({ pathname: '/content-management' })
    // 路由跳转
  }

  const onCloseActive = () => {
    setProductVisible(false)
  }

  const closeModal = () => {
    onCloseActive()
    setSearchBarVisible(false)
  }

  const isSignIn = useMemo(() => auth.authenticated && auth.tokenLoaded, [auth.authenticated, auth.tokenLoaded])
  const onClickBulk = () => {
    if (isSignIn) {
      void router.push({ pathname: '/bulk-procurement' })
    } else {
      void router.push({ pathname: '/sign-in', query: { redirectUrl: '/bulk-procurement' } })
    }
  }

  const { startAnimation, titleHeight, navHeight, titleMarginTop } = useAnimationHeader()

  useEffect(() => {
    onCloseActive()
  }, [router])

  return (
    <header className="fixed top-0 left-0 right-0 z-30">
      <div
        className="px-8 lg:px-30 bg-gray-700 text-white flex items-center transition-all duration-500"
        style={{ height: titleHeight, marginTop: titleMarginTop }}
      >
        <span className="font-bold text-sm">蔡司工业质量商城</span>
      </div>
      <div className="px-8 lg:px-30 bg-white flex flex-row items-center text-sm text-gray-600 transition-all duration-500" style={{ height: navHeight }}>
        <Link href="/" onClick={closeModal} className="mr-4 lg:mr-6">
          <Image
            src="/zeiss-icon.svg"
            alt="zeiss icon"
            width={48}
            height={48}
            className={cls('transition-all duration-500', { 'scale-[0.8333]': startAnimation })}
          />
        </Link>
        {searchBarVisible ? (
          <SearchBar
            onClose={() => {
              setSearchBarVisible(false)
            }}
          />
        ) : (
          <>
            <span className="flex justify-evenly w-1/4 h-full">
              <span
                className={cls(
                  'text-xs lg:text-sm cursor-pointer h-full flex items-center border-b-2 border-b-white',
                  productVisible ? '!border-b-blue-500 font-bold' : ''
                )}
                onClick={onOpenProductCatalog}
              >
                产品目录
              </span>
              <span
                className={cls(
                  'text-xs lg:text-sm cursor-pointer h-full flex items-center border-b-2 border-b-white',
                  isContentManagement ? '!border-b-blue-500 font-bold' : ''
                )}
                onClick={onOpenContent}
              >
                测量洞见
              </span>
              <Link
                className="text-xs lg:text-sm cursor-pointer h-full flex items-center border-b-2 border-b-white"
                href={ABOUT_US_URL}
                target="_blank"
                onClick={onCloseActive}
                rel="noreferrer"
              >
                关于我们
              </Link>
            </span>
            <span className="ml-auto">
              <span
                className="text-gray-600 border-0 h-9 py-2 px-3 lg:py-2.5 lg:px-3.5 rounded-[20px] hover:font-medium text-sm flex items-center hover:bg-gray-100 hover:cursor-pointer"
                onClick={() => {
                  onCloseActive()
                  setSearchBarVisible(true)
                }}
              >
                <SearchIcon className="fill-gray-600" />
                <span className="hidden lg:block ml-2">搜索</span>
              </span>
            </span>
            {!isIpad && (
              <span className="lg:ml-2 hover:cursor-pointer" onClick={onClickBulk}>
                <span className="md:hidden lg:flex text-gray-600 hover:font-medium h-9 py-2.5 !px-3.5 rounded-[1.25rem] ml-auto flex items-center text-xs hover:bg-gray-100">
                  <BulkProcurementIcon className="fill-gray-600" />
                  <span className="text-sm ml-2">批量采购</span>
                </span>
              </span>
            )}

            <span className="ml-2 lg:ml-2">
              <ButtonLikeLink
                href={isSignIn ? '/cart' : '/sign-in?redirectUrl=/cart'}
                text="采购车"
                icon={<ShoppingCardIcon className="stroke-gray-600 stroke-2" />}
                className="text-gray-600 border-0 h-8 lg:h-9 !px-2 lg:py-2.5 lg:!px-3.5 !rounded-[1.25rem] hover:font-medium hover:bg-gray-100 hover:text-gray-600"
                textClassName="hidden lg:block text-sm"
              />
            </span>
            <span className="ml-2">
              <UserStatusBar onClickMenuItem={closeModal} />
            </span>
          </>
        )}
        <ProductCatalog visible={productVisible} setProductVisible={setProductVisible} catalogData={catalogData} />
      </div>
    </header>
  )
}

export default SiteHeader
