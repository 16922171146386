import cls from 'classnames'
import React, { FC } from 'react'

import LoadingIcon from '/public/loading.svg'

const Loading: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={cls('flex justify-center my-20 lg:my-[12.75rem]', className)} data-testid="loading">
      <LoadingIcon className="animate-spin w-18 h-18" />
    </div>
  )
}

export default Loading
