import Big from 'big.js'

declare global {
  interface Number {
    plus(value: number): number
    minus(value: number): number
    multiply(value: number): number
    divide(value: number): number
    mod(value: number): number
    toFixed(fractionDigits: number): string
  }
}

Number.prototype.plus = function (value: number): number {
  return new Big(this.valueOf()).plus(value).toNumber()
}

Number.prototype.minus = function (value: number) {
  return new Big(this.valueOf()).minus(value).toNumber()
}

Number.prototype.multiply = function (value: number) {
  return new Big(this.valueOf()).mul(value).toNumber()
}

Number.prototype.mod = function mod(value: number) {
  return new Big(this.valueOf()).mod(value).toNumber()
}

Number.prototype.divide = function (value: number) {
  return new Big(this.valueOf()).div(value).toNumber()
}

Number.prototype.toFixed = function (fractionDigits: number = 2) {
  return new Big(this.valueOf()).toFixed(fractionDigits)
}
