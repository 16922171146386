import { AxiosError } from 'axios'
import { Middleware } from 'swr'

const errorDispatcher: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const extendedFetcher = (...args: any[]) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const rs = fetcher!(...args)

      Promise.resolve(rs).catch((error: AxiosError) => {
        window.dispatchEvent(Object.assign(new Event('error'), { error }))
      })

      return rs
    }

    return useSWRNext(key, extendedFetcher, config)
  }
}

export default errorDispatcher
