import { useAuth } from '@/hooks/use-auth'
import useDidUpdate from '@/hooks/use-did-update'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

// eslint-disable-next-line prettier/prettier
const AUTH_REQUIRED_PAGES = [
  '/profile',
  '/cart',
  '/bulk-procurement',
  '/quotations/preview',
  '/quotations/[id]',
  '/orders/[id]',
  '/promotions/[id]'
]
const useAuthGuard = () => {
  const router = useRouter()
  const { pathname, asPath, isReady } = router
  const { authenticated } = useAuth()

  const gotoSignPage = useCallback(() => {
    if (isReady && AUTH_REQUIRED_PAGES.includes(pathname) && !authenticated) {
      void router.push(`/sign-in?redirectUrl=${asPath}`)
    }
  }, [asPath, authenticated, isReady, pathname, router])

  useEffect(() => {
    // 通过URL进入, 如果没有登录, 跳转到登录页
    gotoSignPage()
  }, [gotoSignPage])

  useDidUpdate(() => {
    // 退出登录/接口401过期后, 跳转到首页
    gotoSignPage()
  }, [authenticated])
}

export default useAuthGuard
