import { PAGE_BLOCKING_ERROR } from '@/hooks/use-app-page'
import { ErrorResponse } from '@/models/error/error'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { useEffect } from 'react'

export type Handlers = Record<string, Function | null>

export function errorHandling<Error extends ErrorResponse>(handlers?: Handlers) {
  return (error: Error) => {
    if (handlers?.hasOwnProperty(error.code)) {
      const handler = handlers[error.code]
      if (typeof handler === 'function') {
        handlers[error.code]!.call(null, error)
      }
    } else {
      const isBlockError = Object.values(PAGE_BLOCKING_ERROR)
        .flatMap((it) => it)
        .includes(error.code)
      if (!isBlockError) {
        if (error.code === AxiosError.ERR_NETWORK) {
          void message.error('当前网络不通畅，请您重试')
        } else {
          void message.error(error.message || '系统异常，请稍后再试')
        }
      }
    }
  }
}

// Currently error handled globally, using this hook will case duplication
function useError(error?: ErrorResponse, handlers?: Handlers) {
  useEffect(() => {
    if (error) {
      errorHandling(handlers)(error)
    }
  }, [error, handlers])
}

export default useError
