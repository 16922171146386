import Big from 'big.js'

export function isInt(value: number) {
  return Number(value) === value && value % 1 === 0
}

export function isFloat(value: number) {
  return Number(value) === value && value % 1 !== 0
}

export function isFloatStringWithLeastDecimal(value: string, precision: number) {
  const index = value.indexOf('.')
  return index !== -1 && index - value.length <= -(precision + 1)
}

/**
 * Cut off float input without rounding
 * @param value
 * @param precision
 */
export function floorFloat(value: number, precision: number = 2): string {
  return new Big(value).round(precision, Big.roundDown).toNumber().toFixed(precision)
}

export function plus(a: number, b: number): number {
  return new Big(a).plus(b).toNumber()
}

export function minus(a: number, b: number) {
  return new Big(a).minus(b).toNumber()
}

export function multiply(a: number | string, b: number) {
  return new Big(a).mul(b).toNumber()
}

export function divide(a: number, b: number) {
  return new Big(a).div(b).toNumber()
}

export function mod(a: number, b: number) {
  return new Big(a).mod(b).toNumber()
}

export function toFixed(a: number | string, precision: number = 2) {
  return new Big(a).toFixed(precision)
}

export function removeLeadingZeros(input: string): string {
  if (input === '0.0') {
    return '0.0'
  } else if (input === '0.00') {
    return '0.00'
  } else if (input === '00') {
    return '0'
  } else if (input.startsWith('0.')) {
    return input
  } else if (input.startsWith('0')) {
    return parseFloat(input).toString()
  }
  return input
}
export function currency(value: number, fixed: boolean = true, fractionDigits: number = 2): string {
  const fixedValue = fixed ? value.toFixed(fractionDigits) : value.toString()
  const parts = fixedValue.split('.')
  const numberPart = parts[0]
  const decimalPart = parts[1]
  return Number(numberPart).toLocaleString('en-US') + (decimalPart ? '.' + decimalPart : '')
}
