import { useEffect, useState } from 'react'

const useLocalStorage: <T>(key: string) => [storedValue: T | undefined, setValue: (value?: T) => void, loaded: boolean] = <T>(key: string) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const initRawValue = global.localStorage?.getItem(key)!
  const initValue = (initRawValue && JSON.parse(initRawValue)) as T
  const [storedValue, setStoredValue] = useState<T | undefined>(initValue || undefined)

  useEffect(() => {
    if (typeof window === 'undefined') return
    const item = window.localStorage.getItem(key)
    const value = item ? (JSON.parse(item) as T) : undefined
    setStoredValue(value)
    setLoaded(true)
  }, [key])

  const setValue = (value?: T) => {
    if (typeof window === 'undefined') return
    setStoredValue(value)
    value ? window.localStorage.setItem(key, JSON.stringify(value)) : window.localStorage.removeItem(key)
  }

  return [storedValue, setValue, loaded]
}

export default useLocalStorage
