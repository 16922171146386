import { useEffect, useState } from 'react'

export default function useBetterMediaQuery(mediaQueryString: string = '(min-width: 1025px)') {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const mediaQueryList: MediaQueryList = window.matchMedia(mediaQueryString)

    const listener = () => setMatches(mediaQueryList.matches)
    listener()
    mediaQueryList.addEventListener('change', listener)
    return () => mediaQueryList.removeEventListener('change', listener)
  }, [mediaQueryString])

  return matches
}
