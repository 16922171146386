import throttle from 'lodash-es/throttle'
import React, { useEffect, useState } from 'react'

import BackTopIcon from '/public/arrow-up.svg'

const BackTop = () => {
  const [visibleFlag, setVisibleFlag] = useState(false)

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    setVisibleFlag(scrollTop > 200)
  }

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleScroll, 300))
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const scrollToTop = () => {
    document.documentElement.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      {visibleFlag && (
        <div
          className="rounded-full bg-white cursor-pointer shadow flex items-center justify-center fixed w-16 h-16 lg:right-16 lg:bottom-[11.625rem] right-8 bottom-[11.375rem] z-modal"
          onClick={scrollToTop}
        >
          <BackTopIcon className="fill-gray-600 w-[0.875rem] h-[0.875rem]" data-testid="back-top-icon" />
        </div>
      )}
    </>
  )
}

export default BackTop
