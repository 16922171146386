import React from 'react'

import useBetterMediaQuery from './use-better-media-query'

export default function useIpadDetect() {
  const [isIpad, setIsIpad] = React.useState(false)
  const isLandscape = useBetterMediaQuery('(orientation: landscape)')

  React.useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    const ipad = Boolean(userAgent.match(/iPad/i))
    setIsIpad(ipad)
  }, [])

  return { isIpad, isLandscape }
}
