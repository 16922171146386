import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd'
import cls from 'classnames'
import LoadingDefaultIcon from 'public/spinner-default.svg'
import LoadingPrimaryIcon from 'public/spinner.svg'
import { ReactNode } from 'react'

import styles from './index.module.scss'

interface ButtonProps extends AntdButtonProps {
  loading?: boolean
  className?: string
  children: ReactNode
  alone?: boolean
}

const Button = ({ loading = false, className, children, alone = true, ...otherProps }: ButtonProps) => {
  return (
    <AntdButton className={cls(styles.buttonContainer, className)} {...otherProps}>
      {loading ? (
        <div className={styles.loadingContainer}>
          {otherProps.type === 'primary' ? <LoadingPrimaryIcon className="w-5 h-5" /> : <LoadingDefaultIcon className="w-5 h-5" />}
          {!alone && <span className="ml-2">{children}</span>}
        </div>
      ) : (
        children
      )}
    </AntdButton>
  )
}

export default Button
