import cls from 'classnames'

import UserIcon from '/public/user.svg'

interface UserAvatarProps {
  classname?: string
}

const UserAvatar = ({ classname = '' }: UserAvatarProps) => {
  return (
    <div
      className={cls(
        'text-gray-600 border-0 h-9 py-2 px-3 lg:py-2.5 lg:px-3.5 rounded-[20px] hover:font-medium text-sm flex items-center hover:bg-gray-100 hover:cursor-pointer',
        classname
      )}
    >
      <UserIcon className="fill-gray-600" />
      <span className="hidden lg:block ml-2">我的</span>
    </div>
  )
}

export default UserAvatar
