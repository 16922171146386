import { DEFAULT_TIMEZONE } from '@/constants/global'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(DEFAULT_TIMEZONE)

export default dayjs
