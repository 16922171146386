export const TELE_NUMBER = '021-20821199'

export const COMMON_EMAIL = 'shop.metrology.cn@zeiss.com'
export const ABOUT_US_URL = 'https://www.zeiss.com.cn/gongyeceliang/guanyuwomen.html'
export const NEWS_CENTER = 'https://www.zeiss.com.cn/gongyeceliang/new.html'
export const FEED_BACK_URL = 'https://mscapp-u.jingsocial.com/surl/Z19oFS'

// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
export const BULK_PROCUREMENT_TEMPLATE_URL = `${process.env.NEXT_PUBLIC_AZURE_BLOB_URL}/template/%E6%89%B9%E9%87%8F%E9%87%87%E8%B4%AD%E6%96%87%E4%BB%B6%E6%A8%A1%E6%9D%BF.xlsx`

export const DEFAULT_TIMEZONE = 'Asia/Shanghai'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const AZ_BLOB_CONTAINER_PUBLIC = 'ecommerce-resources'
export const AZ_BLOB_CONTAINER_PRIVATE = 'ecommerce-private'
// eslint-disable-next-line prettier/prettier
export const UPGRADE_TOKEN_KEY = 'ecommerce-token-upgrade'

export const NO_CUSTOMER_SERVICE_REQUIRED: string[] = ['/content-management/mobilePreview', '/content-management/pc-preview']
