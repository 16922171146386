'use client'

import { useAuth } from '@/hooks/use-auth'
import UserMenu from '@/ui/components/shared/site-header/user-menu'
import styles from '@/ui/components/shared/site-header/user-status-bar/index.module.scss'
import UserAvatar from '@/ui/components/shared/site-header/user-status-bar/user-avatar'
import { useRouter } from 'next/router'

const AuthorizedUser = ({ onClickMenuItem }: { onClickMenuItem?: () => void }) => {
  const auth = useAuth()
  const router = useRouter()

  const menus = [
    {
      clickHandler: () => {
        onClickMenuItem?.()
        void router.push('/profile')
      },
      title: '个人中心',
    },
    {
      clickHandler: () => {
        onClickMenuItem?.()
        auth.signOut()
      },
      title: '退出登录',
    },
  ]

  return (
    <div className={styles.avatar}>
      <UserAvatar classname={styles.icon} />
      <UserMenu menus={menus} classname={styles.menus} />
    </div>
  )
}

export default AuthorizedUser
