import { errorHandling, Handlers } from '@/hooks/use-error'
import { ErrorResponse } from '@/models/error/error'
import useOriginalSWR, { BareFetcher, Key, SWRResponse } from 'swr'
import { SWRConfiguration } from 'swr/core'
import useOriginalMutation, { MutationFetcher, SWRMutationConfiguration, SWRMutationResponse } from 'swr/mutation'

interface Cancelable {
  cancel?: VoidFunction
}

export const useSWRMutation = <Data = any, Error extends ErrorResponse = ErrorResponse, SWRMutationKey extends Key = Key, ExtraArg = any>(
  key: Key,
  fetcher: MutationFetcher<Data, ExtraArg, SWRMutationKey>,
  options?: SWRMutationConfiguration<Data, ErrorResponse>,
  handlers?: Handlers
): SWRMutationResponse<Data, ErrorResponse> & Cancelable => useOriginalMutation(key, fetcher, { onError: errorHandling(handlers), ...options })

export const useSWR = <Data = any, Error extends ErrorResponse = ErrorResponse>(
  key: Key,
  fetcher: BareFetcher<Data>,
  options?: SWRConfiguration<Data, Error>,
  handlers?: Handlers
): SWRResponse<Data, ErrorResponse> & Cancelable => useOriginalSWR<Data, Error>(key, fetcher, { onError: errorHandling(handlers), ...options })
