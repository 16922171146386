import Link from 'next/link'
import { FC } from 'react'

import BackHomeIcon from '/public/arrow-turn-up-left.svg'
import NotFoundIcon from '/public/not-found.svg'

interface Props {
  backHome?: boolean
}

const Custom404: FC<Props> = ({ backHome = true }) => {
  return (
    <div className="w-full flex flex-col items-center mt-16 mb-30 lg:mt-30 lg:mb-[12.5rem]">
      <NotFoundIcon className="w-[26.875rem] lg:w-[35.875rem]" />
      <p className="font-light text-lg text-gray-600">抱歉，您访问的页面不存在</p>
      {backHome && (
        <Link href="/" className="bg-transparent flex items-center justify-center mt-4">
          <p className="font-bold text-blue-500 text-lg mr-0.5">返回首页</p>
          <BackHomeIcon className="fill-blue-500" />
        </Link>
      )}
    </div>
  )
}

export default Custom404
