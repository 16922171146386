import { useAnimationHeader } from '@/hooks/use-animation-header'
import { SearchItem } from '@/models/products/search'
import { useSearch } from '@/services/search'
import { isChrome } from '@/utils/browser'
import { Input } from 'antd'
import debounce from 'lodash-es/debounce'
import { useRouter } from 'next/router'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import SearchIcon from '/public/search.svg'
import CloseIcon from '/public/xmark.svg'

interface Props {
  onClose: () => void
}

let isComposition = false

const productName = (keyword?: string, content?: string) => {
  if (!content || !keyword) return content ?? ''

  const newKeyword = keyword.replaceAll('(', '\\(').replaceAll(')', '\\)')
  const reg = new RegExp('(\\' + newKeyword + ')', 'i')
  const splits = content.split(reg)
  return splits.map((item, index) => {
    return item.toLocaleLowerCase() === keyword.toLocaleLowerCase() ? (
      <span className="font-bold" key={index}>
        {item}
      </span>
    ) : (
      item
    )
  })
}

const SearchBar: FC<Props> = ({ onClose }) => {
  const [keyword, setKeyword] = useState('')
  const [data, setData] = useState<SearchItem[] | null>()
  const { trigger } = useSearch()
  const router = useRouter()
  const { visibleHeight: top } = useAnimationHeader()

  const fetchData = useRef(
    debounce(async (value: string) => {
      if (!value.trim()) return
      try {
        const data = await trigger({ keyword: value })
        setData(data)
      } catch (error) {
        setData(null)
      }
    }, 300)
  )

  const onChange = useCallback((value: string) => {
    if (isComposition) return
    setKeyword(value)
    if (!value.trim()) {
      setData(null)
    }
    void fetchData.current(value)
  }, [])

  const onCompositionStart = () => {
    isComposition = true
  }

  const onCompositionEnd = (value: string) => {
    isComposition = false
    if (isChrome()) {
      onChange(value)
    }
  }

  const onSearch = useCallback(async () => {
    if (!keyword.trim()) {
      return onChange('')
    }
    await router.push(`/search?keyword=${encodeURIComponent(keyword)}`)
    onClose()
  }, [keyword, onChange, onClose, router])

  const onClickClose = () => {
    setKeyword('')
    setData(null)
    onClose()
  }

  const onClickProduct = useCallback(
    async (id: string) => {
      await router.push(`/products/${id}`)
      onClose()
    },
    [onClose, router]
  )

  const onScroll = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  return (
    <>
      <div className="w-full h-screen bg-gray-700/40 left-0 absolute z-modal" onClick={onClose} style={{ top }} />
      <div className="flex-1 ml-8 mr-7 lg:mr-20 relative">
        <div className="flex items-center w-full">
          <SearchIcon onClick={onSearch} className="cursor-pointer fill-gray-500 w-4 h-4 lg:w-5 lg:h-5 leading-6 lg:mt-1" />
          <Input
            style={{ boxShadow: 'none' }}
            placeholder="输入产品ID/名称/参数"
            className="lg-40 flex-1 h-[5.5] border-0 text-left mx-2 p-0 placeholder:text-gray-500 font-light text-gray-600 rounded-sm text-sm lg:h-6 lg:mx-3 lg:text-base hover:border-0 focus:border-0"
            onChange={(e) => onChange(e.target.value)}
            onCompositionStart={onCompositionStart}
            onCompositionEnd={(e) => onCompositionEnd(e.currentTarget.value)}
            maxLength={20}
            onPressEnter={() => void onSearch()}
            autoFocus
          />
          <CloseIcon className="cursor-pointer w-4 h-4" onClick={onClickClose} />
        </div>
        {data && data.length > 0 && (
          <section data-testid="search-list" className="py-2 bg-white w-full absolute top-18 z-modal rounded max-h-[calc(100vh-7rem)] overflow-y-auto">
            {data.map((item) => (
              <div
                key={item.id}
                onClick={() => void onClickProduct(item.id)}
                className="mb-1 py-2 px-6 hover:bg-gray-200 text-xs text-gray-500 hover:text-gray-700 font-light"
              >
                {productName(keyword, item.name)}
              </div>
            ))}
          </section>
        )}
      </div>
    </>
  )
}

export default SearchBar
