export const theme = {
  token: {
    colorPrimary: '#0072EF',
    colorPrimaryActive: '#0052AA',
    colorPrimaryHover: '#0052AA',
    colorPrimaryBg: '#E6F7FF',
    colorSuccess: '#1E8565',
    colorWarning: '#986F00',
    colorError: '#E71E1E',
    colorBorder: '#DCE3E9',
    colorSplit: '#F2F5F8',
    colorFillContent: '#F2F5F8',
    colorTextDescription: '#606A76',
    borderRadius: 2,
    fontFamily: "'ZeissMYingHei','Noto Sans SC',Consolas,Menlo,Courier,monospace",
  },
  components: {
    App: {
      fontSize: 16,
      colorText: '#32373E',
      lineHeight: 1.5,
    },
  },
}
