import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

function useDidUpdate(callback: EffectCallback, deps: DependencyList) {
  const hasMounted = useRef(false)

  useEffect(() => {
    if (hasMounted.current) {
      callback()
    } else {
      hasMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default useDidUpdate
